html {
	color: #ccc;
	background-color: #282c34;
	font-size: max(1em, calc(8px + 2.2vmin));
}

.hidden {
	display: none;
}

.App {
  min-height: 100vh;
  padding-left: 5vw;
  margin-bottom: 1rem;
  display: flex;
  align-items: left;
  flex-direction: column;
}

.App-landing {
  font-size: max(1em, calc(12px + 2.5vmin));
  width: 80vw;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

a.rainbow-link{
	font-weight: 500;
	color: inherit;
	text-decoration: underline;
	animation: animateRainbowUnderline 10s linear infinite;
}

.no-margin {
	margin: 0;
}

.narrow {
	/* 90vw, but not wider than 700px */
	width: min(90vw, 700px);
}

h1 {
	font-size: calc(20px + 4vmin);
	font-weight: 600;
	margin: 0;
}
h2 {
	font-size: calc(16px + 3vmin);
	font-weight: 600;
	margin: 0;
}

.skill {
	font-weight: bold;
}

.resume-item{
	border-left: white 3px solid;
	animation: animateRainbowBorderColor 10s linear infinite;
	display: inline-block;
	margin: 0;
	padding-left: 0.5rem;
	margin-bottom: 2.4rem;
}


.resume-item > p {
	line-height: 1.2;
	margin: 0;

}

/* modified from https://dev.to/gmeben/*/
/* TODO: browser compatibility? */
.rainbow-text,
.resume-item:hover .resume-item-cta{
  animation: animateSinan 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right,#cf5c5c,#c19b4a,#def2a0,#c6ee4a,#42eca6,#64b3d9,#208ea2,#498ada,#5b73df,#897ed3,#cf5c5c,#c19b4a);
  background-size: 1100% 1000%;
  height: 100vh;
  width: 100vw;
}
@keyframes animateSinan {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}

@keyframes animateRainbowBorderColor {
	0% {border-color: #cf5c5c}
	10% {border-color: #c19b4a}
	20% {border-color: #def2a0}
	30% {border-color: #c6ee4a}
	40% {border-color: #42eca6}
	50% {border-color: #64b3d9}
	60% {border-color: #208ea2}
	70% {border-color: #498ada}
	80% {border-color: #5b73df}
	90% {border-color: #897ed3}
	100% {border-color: #cf5c5c}
}
@keyframes animateRainbowUnderline {
	0% {text-decoration-color: #cf5c5c}
	10% {text-decoration-color: #c19b4a}
	20% {text-decoration-color: #def2a0}
	30% {text-decoration-color: #c6ee4a}
	40% {text-decoration-color: #42eca6}
	50% {text-decoration-color: #64b3d9}
	60% {text-decoration-color: #208ea2}
	70% {text-decoration-color: #498ada}
	80% {text-decoration-color: #5b73df}
	90% {text-decoration-color: #897ed3}
	100% {text-decoration-color: #cf5c5c}
}

.hidden-text{
	color: rgb(158, 158, 158);
	font-weight: 400;
}

.horizontal-right{
	display: flex;
	justify-content: right;
}
